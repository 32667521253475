import { render } from 'react-dom'
import { ReactElement } from 'react'
import { SNACK_TYPE } from '../common/snaks'
import Modal from '../common/modal'

export type ModalMessageType = {
	message?: string
	html?: string
	type?: SNACK_TYPE
	allowOverflow?: boolean
	icon?: string | boolean
	closeBtn?: string
	onAccept?: () => void
}

export type ModalReactType = {
	reactElement?: ReactElement | ((closeModal: () => void) => ReactElement)
	type?: SNACK_TYPE
	customClass?: string
	allowOverflow?: boolean
	icon?: string | boolean
	closeBtn?: string
	onAccept?: () => void
	onClose?: () => void
	autoOpen?: boolean
}

window.makeMessageModal = ({
	message,
	html,
	type = 'common',
	allowOverflow = true,
	icon = 'ok-hand',
	closeBtn = `Понятно`,
	onAccept,
}: ModalMessageType) => {
	const resultHtml = `
    <div class="modal modal--slim">
        <div class="modal-bg"></div>
        <div class="modal-content ${type}">
        <div class="close" data-close-modal></div>
            ${icon ? `<div class="icon icon--${icon}"></div>` : ``}
            ${html || `<p class="typography typography--small">${message}</p>`}
            
            ${
							closeBtn
								? `<div class="btn-group btn-group--centered"><div class="btn btn-action btn--large" data-close-modal>${closeBtn}</div></div>`
								: ``
						}
        </div>
    </div>
    `

	let root = document.createElement(`div`)
	root.innerHTML = resultHtml
	document.body.appendChild(root)

	let target = root.children[0]

	let action = target.querySelector('.btn-action')

	if (action) {
		action.addEventListener('click', () => {
			if (onAccept) {
				onAccept()
			}
		})
	}

	let modal = new Modal({
		target,
		onOpen: () => {},
		onClose: () => {},
	})

	modal.open(allowOverflow)
}

window.makeReactModal = ({
	reactElement,
	type = 'common',
	customClass = undefined,
	allowOverflow = true,
	icon = 'ok-hand',
	closeBtn = `Понятно`,
	onAccept,
	autoOpen = true,
	onClose,
}: ModalReactType): Modal => {
	const resultHtml = `
    <div class="modal ${customClass || `modal--slim`}">
        <div class="modal-bg"></div>
        <div class="modal-content ${type}">
        <div class="close" data-close-modal></div>
            ${icon ? `<div class="icon icon--${icon}"></div>` : ``}
            ${reactElement ? `<div data-bind-react></div>` : ``}
            ${
							closeBtn
								? `<div class="btn-group btn-group--centered"><div class="btn btn-action btn--large" data-close-modal>${closeBtn}</div></div>`
								: ``
						}
        </div>
    </div>
    `

	let root = document.createElement(`div`)
	root.innerHTML = resultHtml
	document.body.appendChild(root)

	let target = root.children[0]

	let action = target.querySelector('.btn-action')

	if (action) {
		action.addEventListener('click', () => {
			if (onAccept) {
				onAccept()
			}
		})
	}

	let modal = new Modal({
		target,
		onOpen: () => {},
		onClose: () => {
			if (typeof onClose === 'function') {
				onClose()
			}
		},
	})

	if (autoOpen) {
		modal.open(allowOverflow)
	}

	if (typeof reactElement === 'function') {
		render(
			reactElement(() => {
				modal.close()
			}),
			root.querySelector('[data-bind-react]')
		)
	} else {
		render(reactElement, root.querySelector('[data-bind-react]'))
	}

	return modal
}
