import isMobile from '../../helpers/isMobile'

const ANIMATE_DURATION = 0.3 * 1000

class Modal {
	target = null

	active = false

	onOpenEval = undefined

	modal_content = undefined

	modal_bg = undefined

	modal_close = undefined

	onOpen = () => {
		if (this.onOpenEval) {
			eval(this.onOpenEval)
		}
	}

	onClose = () => {}

	constructor({ target, onOpen, onClose }) {
		if (!target) {
			console.log(`no find modal target`)
			return false
		}

		this.target = target
		this.target.modal = this

		this.target.classList.add('modal')
		this.target.classList.add('modal--hided')

		if (this.target.querySelector('.modal-content')) {
			this.modal_content = this.target.querySelector('.modal-content')
		}
		if (this.target.querySelector('.modal-bg')) {
			this.modal_bg = this.target.querySelector('.modal-bg')
		}
		if (this.target.querySelector('.modal-close')) {
			this.modal_close = this.target.querySelector('.modal-close')
		}

		if (this.target.getAttribute('onopen')) {
			this.onOpenEval = this.target.getAttribute('onopen')
		}

		if (onOpen) {
			this.onOpen = onOpen
		}

		if (onClose) {
			this.onClose = onClose
		}

		this.bindClose()
	}

	bindClose() {
		if (this.modal_bg) {
			this.modal_bg.addEventListener('click', () => {
				this.close()
			})
		}
		if (this.modal_close) {
			this.modal_close.addEventListener('click', () => {
				this.close()
			})
		}

		;[...this.target.querySelectorAll('[data-close-modal]')].map(el => {
			el.addEventListener('click', () => {
				this.close()
			})
		})
	}

	modalContentAnimateIn() {
		if (this.modal_content) {
			this.modal_content.classList.add(`animate__animated`)
			this.modal_content.classList.add(`animate__fadeInUp`)

			setTimeout(() => {
				this.modal_content.classList.remove(`animate__animated`)
				this.modal_content.classList.remove(`animate__fadeInUp`)
			}, ANIMATE_DURATION)
		}
	}

	modalBgAnimateIn() {
		if (this.modal_bg) {
			this.modal_bg.classList.add(`animate__animated`)
			this.modal_bg.classList.add(`animate__fadeIn`)

			setTimeout(() => {
				this.modal_bg.classList.remove(`animate__animated`)
				this.modal_bg.classList.remove(`animate__fadeIn`)
			}, ANIMATE_DURATION)
		}
	}

	modalCloseAnimateIn() {
		if (this.modal_close) {
			this.modal_close.classList.add(`animate__animated`)
			this.modal_close.classList.add(`animate__fadeIn`)

			setTimeout(() => {
				this.modal_close.classList.remove(`animate__animated`)
				this.modal_close.classList.remove(`animate__fadeIn`)
			}, ANIMATE_DURATION)
		}
	}

	async open(allowOverflow = false) {
		this.target.classList.remove('modal--hided')
		this.target.classList.add('modal--active')

		this.modalContentAnimateIn()
		this.modalBgAnimateIn()
		this.modalCloseAnimateIn()

		this.active = true

		if (!allowOverflow && window.activeModal && window.activeModal.target !== this.target) {
			await window.activeModal.close()
		}

		if (!allowOverflow) {
			window.activeModal = this
		}

		// if (isMobile()) {
		// 	document.body.style.top = `-${window.scrollY}px`
		// 	document.body.style.position = 'fixed'
		// }

		document.body.classList.add('modal-opened')

		setTimeout(() => {
			this.target.classList.add('modal--ready')
		}, ANIMATE_DURATION)
		this.onOpen()

		return this
	}

	async close() {
		// const scrollY = document.body.style.top
		// document.body.style.position = ''
		// document.body.style.top = ''
		//
		// if (scrollY) {
		// 	window.scrollTo({ top: parseInt(scrollY || '0', 10) * -1, left: 0, behavior: 'instant' })
		// }

		this.modalContentAnimateOut()
		this.modalBgAnimateOut()
		this.modalCloseAnimateOut()

		this.target.classList.remove('modal--ready')

		setTimeout(() => {
			this.target.classList.remove('modal--active')
			this.target.classList.add('modal--hided')
		}, ANIMATE_DURATION)

		this.active = false

		document.body.classList.remove('modal-opened')
		this.onClose()
		return this
	}

	modalContentAnimateOut() {
		if (this.modal_content) {
			this.modal_content.classList.add(`animate__animated`)
			this.modal_content.classList.add(`animate__fadeOutDown`)

			setTimeout(() => {
				this.modal_content.classList.remove(`animate__animated`)
				this.modal_content.classList.remove(`animate__fadeOutDown`)
			}, ANIMATE_DURATION)
		}
	}

	modalBgAnimateOut() {
		if (this.modal_bg) {
			this.modal_bg.classList.add(`animate__animated`)
			this.modal_bg.classList.add(`animate__fadeOut`)

			setTimeout(() => {
				this.modal_bg.classList.remove(`animate__animated`)
				this.modal_bg.classList.remove(`animate__fadeOut`)
			}, ANIMATE_DURATION)
		}
	}

	modalCloseAnimateOut() {
		if (this.modal_close) {
			this.modal_close.classList.add(`animate__animated`)
			this.modal_close.classList.add(`animate__fadeOut`)

			setTimeout(() => {
				this.modal_close.classList.remove(`animate__animated`)
				this.modal_close.classList.remove(`animate__fadeOut`)
			}, ANIMATE_DURATION)
		}
	}

	toggle() {
		if (this.active) {
			this.close()
		} else {
			this.open()
		}

		return this
	}

	bindOpener(opener) {
		opener.addEventListener('click', event => {
			event.preventDefault()
			this.open()
		})

		return this
	}
}

export default Modal
